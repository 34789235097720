// HomePage.tsx
import React from "react";
import { Link, useParams } from "react-router-dom";
import { getUser } from "@/queries/user";
import { getDashboard } from "@/queries/dashboard";
import { getComponents } from "@/queries/component";
import DashCom from "@/components/DashCom";
import { getLatestReport, getReport } from "@/queries/report";
import { useReportContext } from "@/stores/ReportContext";
import DashboardList from "@/pages/report/DashboardList";
import {useMediaQuery} from "react-responsive";

type DashboardPageParams = {
  dashboardId: string;
  reportId?: string;
};

const DashboardPage: React.FC = () => {
  const { dashboardId } = useParams<DashboardPageParams>();

  const { reportId } = useReportContext();

  const { data: dashboard, isLoading: dashboardLoading } = getDashboard(
    parseInt(dashboardId),
  );
  const { data: components, isLoading: componentsLoading } = getComponents(
    parseInt(dashboardId),
  );
  const { data: report, isLoading: reportLoading } = getReport({
    report: reportId,
  });


  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }); // Mobile breakpoint

  const style = isMobile ? {} : {
    gridAutoRows: "100px",
  }

  if (dashboardLoading || componentsLoading || reportLoading) {
    return null;
  }

  return (
    <div>
      <DashboardList/>
      <div
        className={"w-full h-full md:grid md:grid-cols-8 md:gap-4 px-8 py-4"}
        style={style}
      >
        {components.map((com) => (
          <DashCom component={com} key={com.id} reportId={report.id}></DashCom>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
