import React, {FunctionComponent, useMemo} from "react";
import _ from "lodash";
import { useExplore } from "@/components/explore/ExploreContext";

export const TableView: FunctionComponent = () => {
  const { zones: rawZones, times } = useExplore();

  const zones = _.clone(rawZones).reverse()

    const { min, max } = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    zones.forEach(zone => {
      zone.values.forEach(value => {
        min = Math.min(min, value);
        max = Math.max(max, value);
      });
    });

    return { min, max };
  }, [zones]);

  const formatValue = (target: number) => {
    if(target === 0) {
      return <span className={"text-gray-600"}>{target}</span>
    }
    // const classes = {
    //   "text-gray-600": val === 0,
    //   "text-green-600": val > 0 && val <= 10,
    //   "text-green-400": val > 10 && val <= 20,
    //   "text-orange-500": val > 20 && val <= 40,
    //   "text-orange-300": val > 40 && val <= 50,
    //   "text-red-600": val > 50 && val <= 70,
    //   "text-red-300": val > 70,
    // };
    // // Find the class to apply
    // const className = _.findKey(classes, (value) => value === true);
    // return <span className={className}>{val}</span>;
    let ratio = 0;
    const sat = '80'
    const lum = '50'
    if(max != min) ratio = (target - min) / (max - min);

    // Calculate hue (Note: Red is at 0, Green is at 120)
    let hue = (1 - ratio) * 120;

    const color = `hsl(${hue}, ${sat}%, ${lum}%)`;
    return <span style={{color}}>{target.toLocaleString()}</span>
  };


  return (
    <div className="flex w-full overflow-hidden text-xs font-mono">
      <div className="flex w-32 overflow-hidden ">
        {/* Fixed Column Table */}
        <table className="w-full whitespace-nowrap mt-[1px]">
          <thead>
            <tr>
              <th className="sticky top-0 text-left p-2 dark:bg-slate-700">
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {zones.map((zone, i) => (
              <tr key={i} className={"even:bg-slate-800"}>
                <td className="py-1 px-2 h-8">{zone.name}</td>
              </tr>
            ))}
            <tr className="">
              <td className="py-1 px-2 h-8">Total</td>
            </tr>
            {/* Spacer */}
            <tr className={"h-12"}></tr>
          </tbody>
        </table>
      </div>
      <div className="flex-1 overflow-x-scroll">
        {/* Scrollable Table */}
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr>
              {zones[0].values.map((_, i) => {
                return (
                  <th className="table-header-cell table-cell-single" key={i}>
                    {times[i]}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {zones.map((zone, rowIndex) => (
              <tr key={rowIndex} className={"even:bg-slate-800"}>
                {zone.values.map((value, colIndex) => (
                  <td
                    className="table-data-cell table-cell-single"
                    key={colIndex}
                  >
                    {formatValue(value)}
                  </td>
                ))}
              </tr>
            ))}
            {/* Totals Row */}
            <tr className="bg-slate-600">
              {zones[0].values.map((foo, colIndex) => (
                <td className="table-data-cell" key={colIndex}>
                  {parseInt(_.sumBy(zones, (zone) => zone.values[colIndex])).toLocaleString('en-US')}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex w-16 overflow-hidden ">
        {/* Fixed Column Table */}
        <table className="w-full whitespace-nowrap mt-[1px]">
          <thead>
            <tr>
              <th className="sticky top-0 text-left p-2 dark:bg-slate-700">
                Totals
              </th>
            </tr>
          </thead>
          <tbody>
            {zones.map((zone, i) => (
              <tr key={i} className={"even:bg-slate-800"}>
                <td className="py-1 px-2 h-8 dark:text-slate-400">
                  {_.sum(zone.values).toLocaleString()}
                </td>
              </tr>
            ))}
            <tr className="">
              <td className="py-1 px-2 h-8"></td>
            </tr>
            {/* Spacer */}
            <tr className={"h-12"}></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
