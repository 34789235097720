export interface IMetricDisplayProps {
  name: string
  value: number
}

export function MetricDisplay ({name, value}: IMetricDisplayProps) {
  return <div className={"w-1/3 h-36 bg-slate-900  border-slate-600 border"}>
    <div className={"uppercase text-center mt-4 text-amber-500 text-lg"}>{name}</div>
    <div className={"text-center mt-4 text-4xl text-slate-200"}>{value.toLocaleString('en-US')}</div>
  </div>
}
