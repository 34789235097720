// AppLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import {HeaderBar2} from "@/components/page/HeaderBar2";
import {Toaster} from "@/components/ui/toaster";
import {ReportForceBar} from "@/components/page/ReportForceBar";
import {RetailHeaderBar} from "@/components/page/RetailHeaderBar";

interface AppLayoutProps {
  enableForceBar?: boolean;
}

const RetailLayout: React.FC<AppLayoutProps> = ({ enableForceBar = true }) => (
  <div className={"h-screen w-full flex flex-col"}>
    <RetailHeaderBar />
    <div className={"flex-grow"}>
      <Outlet />
    </div>
    <Toaster />
  </div>
);


export default RetailLayout;
