// @ts-ignore

import { DataSchema } from "@/lib/types";
import {findClosestTimeIndex} from "@/components/explore/Heatmap";

interface ChartParams {
  width?: number | string;
  height: number;
  data: DataSchema;
  totalRequired?: boolean;
  yAxisTitle?: string;
  xAxisTitle?: string;
  secondaryAxisTitle?: string;
  secondaryAxisSeriesIndex?: number;
}

interface SingleSeriesParams extends ChartParams {}

export const buildSingleSeries = ({
                                    data,
                                    width,
                                    height,
                                    xAxisTitle,
                                    yAxisTitle
                                  }: SingleSeriesParams) => {
  // @ts-ignore
  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      type: "column",
      styledMode: true,
      width: width,
      height: height,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data.labels || [],
      title: {
        text: xAxisTitle || data.values_name || "",
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle || "Values"
      }
    },
    series: [
      {
        name: data.series_name || "",
        data: data.values || [],
      },
    ],
  };

  return options;
};

export const buildMultiAxis = ({
                                 data,
                                 width,
                                 height,
                                 xAxisTitle,
                                 yAxisTitle,
  secondaryAxisSeriesIndex,
  secondaryAxisTitle
                               }: SingleSeriesParams) => {
  // @ts-ignore
  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      type: "column",
      styledMode: true,
      width: width,
      height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: data.labels || [],
      title: {
        text: xAxisTitle || data.values_name || "",
      },
    },

    yAxis: data.multi_series.map((series, index) => ({
      visible: index === 0 || index === secondaryAxisSeriesIndex,
      title: {
        text: series.name
      },
      opposite: index === secondaryAxisSeriesIndex
    })),
    series: data.multi_series.map((series, index) => ({
      name: series.name,
      type: 'column',
      yAxis: index,
      data: series.values
    })),
  };

  return options;
};


export const buildMultiSeriesLine = ({
                                       data,
                                       width,
                                       height,
                                       xAxisTitle,
                                       yAxisTitle
                                     }: SingleSeriesParams) => {
  // @ts-ignore
  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      type: "line",
      styledMode: true,
      width: width,
      height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle || "Values"
      }
    },

    xAxis: {
      categories: data.labels || [],
      title: {
        text: xAxisTitle || data.values_name || "",
      },
    },
    series: data.multi_series.map((s) => {
      return {
        name: s.name,
        data: s.values,
      };
    }),
  };

  return options;
};

export const buildHeatmapData = ({
                                   data,
                                   width,
                                   height,
                                 }: SingleSeriesParams) => {
  //   function generateRandomHeatmapData() {
  //     const data = [];
  //     for (let hour = 0; hour <= 16; hour++) {
  //       for (let gate = 1; gate <= 10; gate++) {
  //         data.push([hour, gate - 1, Math.floor(Math.random() * 100)]);
  //       }
  //     }
  //     return data;
  //   }

  const closestIndex = findClosestTimeIndex(data.labels);


  let heatmapData = [];
  for (let hour = 0; hour < data.labels.length; hour++) {
    for (let gate = 0; gate < data.multi_series.length; gate++) {
      heatmapData.push([hour, gate, data.multi_series[gate].values[hour]]);
    }
  }
  return {
    credits: {
      enabled: false,
    },
    chart: {
      styledMode: true,
      width,
      height,
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: true,
      title: "Passengers"
    },
    tooltip: {
      formatter: function (foo) {
        const x = this.point.x;
        const y = this.point.y;
        const zone = data.multi_series[y];

        return `${zone.name} @ ${data.labels[x]} - ${zone.values[x]} pax`;
      },

    },
    series: [
      {
        type: "heatmap",
        turboThreshold: 50000,
        name: null,
        borderWidth: 1,
        data: heatmapData,
        dataLabels: {
          enabled: false,
          color: "#000000",
        },
      },
    ],
    xAxis: {
      categories: data.labels,
      plotBands: [
        {
          from: closestIndex + 0.5,
          to: closestIndex + 1.5,
          zIndex: 10,
        },
      ],

    },
    yAxis: {
      categories: data.multi_series.map((s) => {
        return s.name;
      }),
      title: {
        text: null,
      },
    },

    colorAxis: {
      min: 0,
      minColor: "#1A237E", // Dark Blue
      maxColor: "#FF8C00", // Medium-Dark Orange
    },
  };
};

export const buildStacked = ({ data, width, height, totalRequired, yAxisTitle, xAxisTitle, secondaryAxisTitle }: SingleSeriesParams) => {
  let options = {}
  if(!totalRequired) {
    options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        styledMode: true,
        width: width,
        height: height,
      },
      title: {
        text: "",
      },
      plotOptions: {
        column: {
          stacking: "stacked",
          dataLabels: {
            enabled: false,
          },
        },
      },
      yAxis: {
        title: yAxisTitle || ""
      },
      xAxis: {
        categories: data.multi_series_labels || [],
        title: {
          text: xAxisTitle || data.values_name || "",
        },
      },
      series: data.multi_series.map((s) => {
        return {
          name: s.name,
          data: s.values,
        };
      }),
    };
  } else {
    const lineSeriesData = data.labels.map((_, idx) => {
      return data.multi_series.reduce((sum, series) => sum + series.values[idx], 0);
    });
    // @ts-ignore
    // @ts-ignore
    options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        styledMode: true,
        width: width,
        height: height,
      },
      title: {
        text: "",
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      xAxis: {
        categories: data.labels || [],
        title: {
          text: xAxisTitle || data.values_name || "",
        },
      },
      yAxis: [{ // Primary yAxis for columns
        title: {
          text: yAxisTitle ?? "Value",
        },
      }, {  // Secondary yAxis for line
        title: {
          text: secondaryAxisTitle ?? "Total",
        },
        opposite: true,
      }],
      series: data.multi_series.map((s) => {
        return {
          name: s.name,
          data: s.values,
        };
      })
        // @ts-ignore
        .concat([{
          name: 'Total',
          type: 'line', // @ts-ignore
          yAxis: 1,
          data: lineSeriesData
        }]),
    }
  }

  return options;
};

export const buildStackedPercent = ({
                                      data,
                                      width,
                                      height,
                                      xAxisTitle,
                                      yAxisTitle
                                    }: SingleSeriesParams) => {
  // @ts-ignore
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      styledMode: true,
      width: width,
      height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        stacking: "percent",
        dataLabels: {
          enabled: false,
        },
      },
    },
    yAxis: {
      title: yAxisTitle || ""
    },
    xAxis: {
      categories: data.multi_series_labels || [],
      title: {
        text: xAxisTitle || data.values_name || "",
      },
    },
    series: data.multi_series.map((s) => {
      return {
        name: s.name,
        data: s.values,
      };
    }),
  };

  return options;
};

interface PercentageChart extends SingleSeriesParams {
  primary?: string
  secondary?: string
  tooltipTitle?: string
}

export const buildPercentageChart = ({
                                       data,
                                       width,
                                       height,
                                       primary,
                                       secondary,
                                       tooltipTitle
                                     }: PercentageChart) => {
  // @ts-ignore
  const percent = Math.floor((data.pie_value * 100) / data.pie_total);

  const value = data.pie_value;
  const rest = data.pie_total - data.pie_value;

  const options = {
    credits: {
      enabled: false,
    },

    chart: {
      styledMode: true,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      width,
      height,
    },
    title: {
      text: `${percent}%`,
      align: "center",
      verticalAlign: "middle",
      y: 75,
    },
    subtitle: {
      text: `${tooltipTitle ?? ''}`,
      align: "center",
      verticalAlign: "middle",
      y: 90,
    },
    tooltip: {
      pointFormat: "{point.y} <br/> <b>({point.percentage:.1f}%)</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          // distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "80%",
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        innerSize: "50%",
        data: [
          [primary ?? data.pie_value_name, value],
          [secondary ?? "Others", rest],
        ],
      },
    ],
  };

  return options;
};
