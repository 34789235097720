import {MetricDisplay} from "@/components/retail/RetailViz";
import {getRetailReport, RetailReport} from "@/queries/retail";
import {useReportContext} from "@/stores/ReportContext";
import {ExploreContext} from "@/components/explore/ExploreContext";
import {trimZones} from "@/components/explore/ExploreView";
import {useSessionData} from "@/stores/SessionDataContext";
import {TableView} from "@/components/explore/ExploreTable";
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {buildMultiAxis} from "@/viz/chart-data";

function Card({ title, children, wrapperClassName = '', innerClassName = '' }) {
  return (
    <div className={`bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-4 h-90 md:h-auto ${wrapperClassName}`}>
      <div className="px-4 py-4 text-slate-300 border-b border-slate-700">
        {title}
      </div>
      <div className={`p-4 ${innerClassName}`}>
        {children}
      </div>
    </div>
  );
}



function GateDisplay ({summary} : {summary: RetailReport}) {
  const { airport_config } = useSessionData();

  const { zones, times } = trimZones(
    summary.gates,
    airport_config.start_hour,
    airport_config.end_hour,
    airport_config.interval_size,
  );

  return <Card title={"Relevant Gates"} innerClassName={""}>
        <ExploreContext.Provider
      value={{
        zones,
        times,
        concerns: []
      }}
    >
      <TableView />
    </ExploreContext.Provider>
  </Card>
}


function ChartDisplay({summary}: {summary: RetailReport}) {
  return     <Card title="Traffic Overview">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildMultiAxis({
          height: 400,
          data: summary.chart_hourly_breakdown,
          xAxisTitle: "Time",
          secondaryAxisSeriesIndex: 1
        })}
      />
    </Card>
}

function RetailSummary({stores = []}: {stores: number[]}) {
  const {reportId} = useReportContext()
  const {data: summary} = getRetailReport({
    report: reportId,
    stores
  })

  if(!summary) {
    return null
  }

  return <div className={"w-full"}>
    <div className="flex space-x-4">
      <MetricDisplay name={"Total Passengers @ DFW"} value={summary.total_pax}/>
      <MetricDisplay name={"Total Flights @ DFW"} value={summary.total_flights}/>
      <MetricDisplay name={"Flights in Service Area"} value={summary.service_flights}/>
    </div>
    <div className="flex space-x-4 mt-4">
      <MetricDisplay name={"Remaining Departures"} value={summary.remain_dep_flights}/>
      <MetricDisplay name={"Remaining DEP Passengers"} value={summary.remaining_pax_dep}/>
      <MetricDisplay name={"Total Pax Remaining"} value={summary.remain_pax}/>
    </div>
    {stores.length === 1 && <div className="flex space-x-4 mt-4">
      <MetricDisplay name={"Flights Next Hour"} value={summary.hour_flights}/>
      <MetricDisplay name={"Pax Next Hour"} value={summary.hour_pax}/>
      <MetricDisplay name={"Current Pax @ Zone"} value={summary.current_pax}/>
    </div>}

    <div className={"w-full highcharts-dark"}>
      <ChartDisplay summary={summary} />
    </div>
    {summary.gates && <GateDisplay summary={summary} />}

  </div>
}

export default RetailSummary;
