import React, { FunctionComponent, useMemo, useState } from "react";
import { ExploreConfig } from "@/types/explore";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { HeatMapView } from "@/components/explore/Heatmap";
import _ from "lodash";
import { ExploreContext, ZoneData } from "@/components/explore/ExploreContext";
import { TableView } from "@/components/explore/ExploreTable";
import { useReportContext } from "@/stores/ReportContext";
import { getZoneData } from "@/queries/report";
import { useSessionData } from "@/stores/SessionDataContext";
import { ConcernView } from "@/components/explore/ConcernView";

function getTimeFromIndex(
  index: number,
  intervalSize: number = 10,
  startHour = 0,
  endHour = 24,
): string {
  // Determine how many intervals per hour.
  const intervalsPerHour = 60 / intervalSize;

  // Calculate total number of intervals
  const totalIntervals = (endHour - startHour) * intervalsPerHour;

  // Translate the index to the corresponding hour and minute.
  const hour = Math.floor(index / intervalsPerHour) + startHour;
  const minute = (index % intervalsPerHour) * intervalSize;

  // Build a time string and return it.
  return `${hour}:${minute.toString().padStart(2, "0")}`;
}

export function trimZones(
  zones: ZoneData[],
  startHour: number,
  endHour: number,
  intervalSize: number = 10,
): { zones: ZoneData[]; times: string[] } {
  // Calculate start and end indices based on input hours
  const startIndex = (startHour * 60) / intervalSize;
  const endIndex = (endHour * 60) / intervalSize;

  let times: string[] = [];

  if (zones.length > 0 && zones[0].values.length > 0) {
    _.forEach(zones[0].values, (_, index) => {
      times.push(getTimeFromIndex(index, intervalSize, startHour, endHour));
    });
  }

  return { zones, times };
}

export const ExploreView: FunctionComponent<{ config: ExploreConfig }> = ({
  config,
}) => {
  const [exploreMode, setExploreMode] = useState("heatmap");

  const error = useMemo(() => {
    return null;
  }, [config]);

  const { reportId } = useReportContext();
  const {
    data: zoneData,
    isLoading,
    isError,
    isSuccess,
  } = getZoneData(config, reportId, !error);
  const { airport_config } = useSessionData();

  if (error) {
    return <div className={"error-msg"}>{error}</div>;
  }
  if (isLoading) return <div>Please wait while we load your data.</div>;

  if (isError || !isSuccess) {
    return (
      <div className={"error-msg"}>
        Something went wrong when fetching the data you requested
      </div>
    );
  }

  if (!zoneData.zones || zoneData.zones.length == 0) {
    return (
      <div className={"error-msg"}>Your request did not return any results</div>
    );
  }

  const { zones, times } = trimZones(
    zoneData.zones,
    airport_config.start_hour,
    airport_config.end_hour,
    airport_config.interval_size,
  );

  return (
    <div className={"w-full"}>
      <div className="flex items-center">
        <span className={"text-sm mr-2"}>View as:</span>
        <Tabs
          value={exploreMode}
          onValueChange={(value) => setExploreMode(value)}
        >
          <TabsList>
            <TabsTrigger value="heatmap">Heatmap</TabsTrigger>
            <TabsTrigger value="table">Table</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <ExploreContext.Provider
        value={{
          zones,
          times,
          concerns: zoneData.concerns,
        }}
      >
        <div
          className={"dark:bg-slate-900 dark:border-slate-600 border p-2 mt-4"}
        >
          {exploreMode === "heatmap" && <HeatMapView measure={config.measure} />}
          {exploreMode === "table" && <TableView />}
        </div>
        {zoneData.concerns && zoneData.concerns.length > 0 && <ConcernView />}
      </ExploreContext.Provider>
    </div>
  );
};
