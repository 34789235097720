import { components } from "@/lib/openapi";
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReportProvider } from "@/stores/ReportContext";

export type SessionDataType = components["schemas"]["UserSchema"];

// Extend the UserSchema to include derived properties
export type ExtendedSessionDataType = SessionDataType & {
  isBrand: boolean;
  isAirport: boolean;
};

declare global {
  interface Window {
    sessionData: SessionDataType;
  }
}

const SessionDataContext = createContext<ExtendedSessionDataType | null>(null);

const SessionDataProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [sessionData, setSessionData] = useState<ExtendedSessionDataType | null>(
    null
  );

  // Function to create the extended session data
  const createExtendedSessionData = (data: SessionDataType): ExtendedSessionDataType => {
    return {
      ...data,
      isBrand: data.session_type === "brand",
      isAirport: data.session_type === "airport",
    };
  };

  // Fetch the session data
  useEffect(() => {
    if (window.sessionData) {
      setSessionData(createExtendedSessionData(window.sessionData as SessionDataType));
    }
  }, []);

  return (
    <SessionDataContext.Provider value={sessionData}>
      <ReportProvider>{children}</ReportProvider>
    </SessionDataContext.Provider>
  );
};

// Custom hook to use the sessionData context
export const useSessionData = () => useContext(SessionDataContext);

export default SessionDataProvider;
